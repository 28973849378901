<template>
  <div class="content">
    <!-- <div class="testing-container">
      <p>columns qt: {{columns.length}}</p>
    </div> -->

    <div v-if="updating" class="qt-block-ui"></div>

    <!-- Add task (sidebar) -->
    <b-sidebar id="sidebar-right-task" ref="addTaskSide" :title="$t('catalog.create')" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <span v-b-toggle.sidebar-right-task class="material-symbols-outlined arrow-back">keyboard_double_arrow_left</span>
        <p>{{currentBoard.title}}</p>
        <span class="material-symbols-outlined">chevron_right</span>
        <p class="text-yup">{{columnTitle}}</p>
        <span v-b-toggle.sidebar-right-task class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col title-column" v-if="fetched">
        <div class="title-task">
          <input v-if="task.edit" v-focus v-model="leadName" id="titleBoard" @change="handleEdit(task, 'titleBoard')" @keyup.enter="task.edit = !task.edit" @keydown.esc="task.edit = false" type="text" :placeholder="!leadName ? task.title : leadName" class="form-control border-0">
          <p v-else class="not-editing mb-0" @click="$set(task, 'edit', !task.edit)">{{!leadName ? task.title : leadName}}</p>
          <!-- <code>{{column.deals.length}}</code> -->
          <svg v-if="!task.edit" @click="$set(task, 'edit', !task.edit)" role="button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="heroicon text-yup" :class="viewType === 'grid' ? '' : ''"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" /></svg>
        </div>
        <p class="text-right">{{$t('workflow.workspace.board.card.no-exceed')}}</p>
      </div>
      <div class="col sidebar-item assign-user-column">
        <span class="label-sidebar-item">{{$t('workflow.workspace.card.assign')}}</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path data-v-1c0b824d="" stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <select-input
          label="name"
          class="w-100"
          :reduce="(itemTask) => itemTask"
          v-model="userQueryCard"
          :options="userAndDepCard"
          :clearable="false"
        />
        <!-- @option:selected="insertUser()" -->
      </div>
      <!-- <div class="col responsable-column">
        <p>{{$t('workflow.workspace.board.card.responsable')}}</p>
        <img src="@/assets/w-1.png" alt="Woman 1" srcset="">
        <img src="@/assets/w-2.png" alt="Woman 2" srcset="">
        <img src="@/assets/w-2.png" alt="Woman 2" srcset="">
        <span role="button" class="material-symbols-outlined">add_circle</span>
      </div> -->
      <div class="col sidebar-item description-column">
        <p>{{$t('app.description')}}</p>
        <div id="lead-description" class="input-group mb-2">
          <textarea rows="7" v-model="leadDescription" type="text" class="form-control w-100" :placeholder="$t('app.description')"></textarea>
        </div>
        <p class="text-right">{{$t('workflow.workspace.board.card.char-exceed')}}</p>
      </div>
      <div class="col sidebar-item status-column">
        <div class="title-task">
          <!-- <code>{{currentTask.status}}</code> -->
          <span class="label-sidebar-item">{{$t('generic-str.priority')}}</span>
          <select-input
            class="w-100"
            label="text"
            v-model="taskStatus"
            :reduce="(cardPriorityOptions) => cardPriorityOptions.value"
            :options="cardPriorityOptions"
            :clearable="false"
            :editable="false"
          />
        </div>
      </div>
      <div class="col sidebar-item date-column">
        <span class="label-sidebar-item">Data de vencimento</span>
        <Calendar id="icon" ref="calendarDate" v-model="taskDate" :value="taskDate" @input="handleCalendar" dateFormat="dd/mm/yy" :showTime="true" :showIcon="true" />
        <!-- <code>{{taskDate}}</code> -->
      </div>
      <!-- <div class="col sidebar-item value-column">
        <span class="label-sidebar-item">Valor</span>
        <input class="form-control" placeholder="R$ 0,00" v-money="money" maxlength="13" v-model="taskValue" type="text">
      </div> -->
      <div class="col sidebar-item offering-column" v-if="false">
        <span class="label-sidebar-item">{{$t('workflow.workspace.products.sidebar.title')}}</span>
        <select-input
          class="w-100"
          label="title"
          v-model="offering.id"
          :reduce="(entry) => entry.id"
          :options="offerings"
          :clearable="false"
          :editable="false"
        >
          <template #list-header>
            <li><button v-b-toggle.sidebar-right-add-products class="btn btn-outline-primary w-100 text-center mb-2">{{$t('catalog.register.title')}}</button></li>
          </template>
        </select-input>

        <div class="form-group">
          <label for="quantityInput">{{$t('app.quantity')}}</label>
          <input
            min="1"
            type="number"
            class="form-control"
            id="quantityInput"
            aria-describedby="quantityHelp"
            placeholder="Entre com a quantidade"
            :disabled="!offering.id"
            v-model="offering.quantity">
        </div>

        <div class="form-group">
          <label for="recurrenceInput">Recorrência:</label>
          <select
            class="form-control"
            id="recurrenceInput"
            aria-describedby="recurrenceHelp"
            :disabled="!offering.id"
            v-model="offering.recurrence">
            <option value="single">Único</option>
            <option value="recurrent">Recorrente</option>
          </select>
        </div>

        <div class="form-group">
          <label for="discountInput">Desconto:</label>
          <input
            min="0"
            type="number"
            class="form-control"
            id="discountInput"
            aria-describedby="discountHelp"
            placeholder="Entre com o desconto"
            :disabled="!offering.id"
            v-model="offering.discount">
        </div>
      </div>
      <div class="col btn-wrapper">
        <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-right-task>{{$t('app.cancel')}}</button>
        <button class="btn btn-primary mt-3 ml-auto" @click="addCard()" :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}" :disabled="isSending">{{$t('app.save')}}</button>
      </div>
    </b-sidebar>
    <!--/Add task (sidebar) -->
    <!-- Show task (sidebar) -->
    <b-sidebar id="sidebar-right-show-task" ref="showTaskSide" :title="$t('catalog.create')" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <!-- <code>{{currentTask.id}}</code> -->
        <span v-b-toggle.sidebar-right-show-task class="material-symbols-outlined arrow-back">keyboard_double_arrow_left</span>
        <p>{{currentTask.title}}</p>
        <span class="material-symbols-outlined">chevron_right</span>
        <p class="text-yup">{{columnTitle}}</p>
        <span v-b-toggle.sidebar-right-show-task class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col title-column">
        <div class="title-task">
          <input v-if="currentTask.edit" v-focus v-model="leadName" id="titleBoard" @change="handleEdit(currentTask, 'titleBoard')" @keyup.enter="currentTask.edit = !currentTask.edit" @keydown.esc="currentTask.edit = false" type="text" :placeholder="!leadName ? currentTask.title : leadName" class="form-control border-0">
          <p v-else class="not-editing mb-0" @click="$set(currentTask, 'edit', !currentTask.edit)">{{!leadName ? currentTask.title : leadName}}</p>
          <!-- <code>{{column.deals.length}}</code> -->
          <!-- <button class="btn btn-add-responsible" @click="addResponsible = !addResponsible"><svg data-v-1c0b824d="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path data-v-1c0b824d="" stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></button> -->
        <button class="btn btn-add-responsible" @click="addResponsible = !addResponsible"><span class="material-symbols-outlined">{{!addResponsible ? 'add' : 'remove'}}</span></button>
        </div>
        <p class="text-right">{{$t('workflow.workspace.board.card.no-exceed')}}</p>
      </div>
      <!-- <div class="col responsable-column">
        <p>{{$t('workflow.workspace.board.card.responsable')}}</p>
        <img src="@/assets/w-1.png" alt="Woman 1" srcset="">
        <img src="@/assets/w-2.png" alt="Woman 2" srcset="">
        <img src="@/assets/w-2.png" alt="Woman 2" srcset="">
        <span role="button" class="material-symbols-outlined">add_circle</span>
      </div> -->
      <div v-if="userAndDepCard.length > 0" class="col sidebar-item assign-user-column">
        <span class="label-sidebar-item">{{$t('workflow.workspace.card.assign')}}</span>
        <svg data-v-1c0b824d="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path data-v-1c0b824d="" stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <select-input
          v-if="addResponsible"
          label="name"
          class="w-100"
          :reduce="(itemTask) => itemTask"
          v-model="userQueryCardEdit"
          :options="userAndDepCard"
          :clearable="false"
        />
        <!-- @option:selected="insertUser()" -->
        <div class="w-100 profile-wrapper">
          <!-- <template v-for="user in currentTask.users">
            <div
              class="profile"
              :key="`carduser:${currentTask.id}:${user.id}`"
              :title="`teamuser:${user.id}`"
              :style="{ backgroundColor: $strToColor(user.name) }">
                {{user.name | initials}}
              </div>
          </template> -->
          <template v-if="currentTask.user">
            <div v-if="seen" class="profile-around" :id="currentTask.user.id">
              <div
                class="profile"
                :key="`carduser:${currentTask.id}:${currentTask.user.id}`"
                :title="`teamuser:${currentTask.user.id}`"
                :style="{ backgroundColor: $strToColor(currentTask.user.name) }">
                  {{currentTask.user.name | initials}}
                </div>
                <button @click="removeResponsible" class="btn btn-remove-responsible"><span class="material-symbols-outlined">close</span></button>
            </div>
          </template>
        </div>
      </div>
      <div class="col sidebar-item">
        <div class="w-100 profile-wrapper">
          <p class="w-100 mb-1">Contato</p>
          <template v-if="currentTask.contact_id">
            <div
              class="profile"
              :key="`carduser:${currentTask.id}:${currentTask.contact.id}`"
              :title="`teamuser:${currentTask.contact.id}`"
              :style="{ backgroundColor: $strToColor(currentTask.contact.name ? currentTask.contact.name : currentTask.contact.first_name) }">
                {{currentTask.contact.name ? currentTask.contact.name : currentTask.contact.first_name | initials}}
              </div>
          </template>
        </div>
      </div>
      <div class="col sidebar-item description-column">
        <p>{{$t('app.description')}}</p>
        <div id="lead-description" class="input-group mb-2">
          <textarea rows="7" v-model="currentTask.description" type="text" class="form-control w-100" :placeholder="$t('app.description')"></textarea>
        </div>
        <p class="text-right">{{$t('workflow.workspace.board.card.char-exceed')}}</p>
      </div>
      <div class="col sidebar-item status-column">
        <div class="title-task">
          <!-- <code>{{currentTask.status}}</code> -->
          <span class="label-sidebar-item">{{$t('generic-str.priority')}}</span>
          <select-input
            class="w-100"
            label="text"
            v-model="currentTask.priority"
            :reduce="(cardPriorityOptions) => cardPriorityOptions.value"
            :options="cardPriorityOptions"
            :clearable="false"
            :editable="false"
          />
        </div>
      </div>
      <div class="col sidebar-item date-column">
        <span class="label-sidebar-item">Data de vencimento</span>
        <Calendar id="icon" ref="calendarDate" v-model="currentTask.due_date" :value="currentTask.due_date" @input="handleCalendar" dateFormat="dd/mm/yy" :showTime="true" :showIcon="true" />
        <!-- <code>{{taskDate}}</code> -->
      </div>
      <!-- <div class="col sidebar-item value-column">
        <span class="label-sidebar-item">Valor</span>
        <input class="form-control" placeholder="R$ 0,00" v-money="money" maxlength="13" v-model.lazy="currentTask.value" type="text">
      </div> -->
      <div class="col sidebar-item offering-column">
        <span class="label-sidebar-item">{{$t('workflow.workspace.products.sidebar.title')}}</span>
        <select-input
          class="w-100"
          label="title"
          v-model="offering.id"
          :reduce="(entry) => entry.id"
          :options="offerings"
          :clearable="false"
          :editable="false"
        >
          <template #list-header>
            <li><button v-b-toggle.sidebar-right-add-products class="btn btn-outline-primary w-100 text-center mb-2">{{$t('catalog.register.title')}}</button></li>
          </template>
        </select-input>

        <div class="form-group">
          <label for="quantityInput">{{$t('app.quantity')}}</label>
          <input
            min="1"
            type="number"
            class="form-control"
            id="quantityInput"
            aria-describedby="quantityHelp"
            :placeholder="$t('workflow.workspace.products.sidebar.title.qt-desc')"
            :disabled="!offering.id"
            v-model="offering.quantity">
        </div>

        <div class="form-group">
          <label for="recurrenceInput">{{$t('app.recurrence')}}:</label>
          <select
            class="form-control"
            id="recurrenceInput"
            aria-describedby="recurrenceHelp"
            :disabled="!offering.id"
            v-model="offering.recurrence">
            <option value="single">{{$t('app.unique')}}</option>
            <option value="recurrent">{{$t('app.recurrent')}}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="discountInput">{{$t('catalog.register.discount')}}:</label>
          <input
            min="0"
            type="number"
            class="form-control"
            id="discountInput"
            aria-describedby="discountHelp"
            :placeholder="$t('workflow.workspace.products.sidebar.title.discount-desc')"
            :disabled="!offering.id"
            v-model="offering.discount">
        </div>
      </div>

      <!--
      <div class="col input-group custom-fields-group">
        <span role="button" class="toggle-custom" @click="toggleSidebar('collapse-1'), customToggle = !customToggle">{{$tc('app.comment', 2)}} <span class="material-symbols-outlined" :class="{rotate: customToggle}">expand_more</span></span>
        <b-collapse id="collapse-1" class="mt-2 w-100">
          <textarea rows="3" v-model="taskComment" type="text" class="form-control w-100" :placeholder="$tc('app.comment', 1)"></textarea>
          <! -- <code>{{customFields}}</code> -- >
          <div class="px-1 py-4">
            <template v-for="comment in currentTask.comments">
              <div class="d-flex comment" :key="comment.id">
                <div class="bar">
                  <div class="profile">
                    {{comment.user.name | initials}}
                  </div>
                </div>
                <div class="flex-grow-1">
                  <header class="d-flex header-comment align-items-center pb-2">
                    <div>{{comment.user.name}}</div>
                    <time :datetime="comment.created_at">{{comment.created_at | relativeDate}}</time>
                  </header>
                  <div class="body">
                    {{comment.body}}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </b-collapse>
      </div>
      -->

      <div class="col btn-wrapper">
        <button
          class="btn btn-outline-primary mt-3"
          v-b-toggle.sidebar-right-show-task>{{$t('app.cancel')}}</button>
        <button
          @click="handleEditTask"
          class="btn btn-primary mt-3 ml-auto"
          :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}"
          :disabled="isSending">{{$t('app.save')}}</button>
      </div>
    </b-sidebar>
    <!--/Show task (sidebar) -->
    <!-- Filter task (sidebar) -->
    <b-sidebar id="sidebar-right-filter-task" ref="showTaskSide" :title="$t('catalog.create')" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <div class="header-info-wrapper">
          <p class="sidebar-title">{{$tc('app.filter', 2)}}</p>
          <p class="sidebar-desc">{{$t('workflow.workspace.board.card.filter-desc')}}</p>
        </div>
        <span v-b-toggle.sidebar-right-filter-task class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col title-column">
        <div class="form-wrapper">
          <form @submit.prevent="search" class="form-group col px-0 mb-0">
            <div class="input-group">
              <input
                :placeholder="`${$t('app.search')}...`"
                type="text"
                class="form-control"
                :v-model="searchQuery"
                ref="searchInput"
              />
              <div class="input-group-prepend">
                <button @click="search" type="button" class="btn icon_btn m-0">
                  <span class="material-symbols-outlined"> search </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="col btn-wrapper">
        <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-right-filter-task>{{$t('app.cancel')}}</button>
        <button class="btn btn-primary mt-3 ml-auto">{{$t('app.save')}}</button>
      </div> -->
    </b-sidebar>
    <!--/Filter task (sidebar) -->

    <!-- Add user (sidebar) -->
    <b-sidebar id="sidebar-right-manage" ref="showTaskSide" :title="$t('workflow.workspace.board.manage.title')" no-header right backdrop shadow>
      <div class="qt-block-ui" v-if="savingTeam"></div>
      <div class="col sidebar-header">
        <div class="header-info-wrapper">
          <p class="sidebar-title">{{$t('workflow.workspace.board.manage.title')}}</p>
          <p class="sidebar-desc">{{$t('workflow.workspace.board.manage.description')}}</p>
        </div>
        <span v-b-toggle.sidebar-right-manage class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col sidebar-item title-column">
        <div class="form-wrapper">
          <!-- <form @submit.prevent="addUserSearch" class="form-group col px-0 mb-0">
            <div class="input-group">
              <input
                :placeholder="$t('workflow.workspace.board.card.search-desc')"
                type="text"
                class="form-control"
                :v-model="addUserQuery"
                ref="userSearchhInput"
              />
              <div class="input-group-prepend">
                <button @click="addUserSearch" type="button" class="btn icon_btn m-0">
                  <span class="material-symbols-outlined"> search </span>
                </button>
              </div>
            </div>
          </form> -->
          <select-input
            label="name"
            :reduce="(item) => item"
            v-model="userQuery"
            @search="(search, loading) => {
              if(search.length > 2) fetchUsersDepartments(search)
            }"
            @option:selected="insertToTeam()"
            :options="usersAndDepartments"
            :clearable="false"
          />
          <button class="btn btn-outline-primary mt-3 w-100">{{$t('home.invite-card.btn-invite')}}</button>
        </div>
      </div>
      <div class="col sidebar-item team-column">
        <span class="label-sidebar-item">{{$t('app.team')}}</span>
        <!-- <img class="profile-img" src="@/assets/w-1.png" alt="Woman 1" srcset="">
        <img class="profile-img" src="@/assets/w-2.png" alt="Woman 2" srcset="">
        <img class="profile-img" src="@/assets/w-2.png" alt="Woman 2" srcset=""> -->
        <div class="team-wrapper" v-if="manageTeamList">
          <span class="team-profile" v-for="(teamItem, index) in manageTeamList" :key="index">{{teamItem.name | initials}}</span>
        </div>
        <div class="copy-wrapper" role="button" @click="copyLink('sidebar')">
          <span id="copy-btn-2" class="material-symbols-outlined item-action">link</span>
          <span class="text-yup">{{$t('app.copy-link')}}</span>
          <!-- HTML title specified via default slot -->
          <b-tooltip target="copy-btn-2" placement="bottom" triggers="click" :show.sync="showTooltip2" custom-class="purple-tooltip">
            Link copiado!
          </b-tooltip>
        </div>
      </div>
      <template v-for="department in currentBoard.departments">
        <div class="col sidebar-item user-column" :key="`teamuser:${department.id}`">
          <!-- <img class="profile-img" src="@/assets/w-1.png" alt="Woman 1" srcset=""> -->
          <div class="profile">{{department.name | initials}}</div>
          <div class="name-wrapper">
            <p>{{department.name}}</p>
          </div>
          <div class="right-options">
            <div class="date-wrapper">
              <span class="material-symbols-outlined">calendar_today</span>
              {{department.created_at | displayDate(months, today, $i18n.locale)}}
            </div>
            <!-- <span class="material-symbols-outlined" role="button">delete_forever</span> -->
          </div>
        </div>
      </template>
      <template v-for="user in currentBoard.users">
        <div class="col sidebar-item user-column" :key="`teamuser:${user.id}`">
          <!-- <img class="profile-img" src="@/assets/w-1.png" alt="Woman 1" srcset=""> -->
          <div class="profile">{{user.name | initials}}</div>
          <div class="name-wrapper">
            <p>{{user.name}}</p>
            <span>{{user.email || ''}}</span>
          </div>
          <div class="right-options">
            <div class="date-wrapper"><span class="material-symbols-outlined">calendar_today</span> {{user.created_at | displayDate(months, today, $i18n.locale)}}</div>
            <!-- <span class="material-symbols-outlined" role="button">delete_forever</span> -->
          </div>
        </div>
      </template>
      <div class="col btn-wrapper">
        <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-right-manage>{{$t('app.cancel')}}</button>
        <button class="btn btn-primary mt-3 ml-auto" @click="saveTeam()">{{$t('app.save')}}</button>
      </div>
    </b-sidebar>
    <!--/Add user (sidebar) -->

    <div class="page-content container-fluid settings">
      <div class="row">
        <div class="col-12 col-sm">
          <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Pipeline" :subtitle="breadcrumbInfo.value === '' ? breadcrumbInfo.text : breadcrumbInfo.value"/>
          <!-- Search column -->
          <!-- <div class="col-8 mx-auto mb-5">
            <div class="form-wrapper">
              <form @submit.prevent="search" class="form-group col px-0 mb-0">
                <div class="input-group">
                  <input
                    placeholder=""
                    type="text"
                    class="form-control"
                    :v-model="searchQuery"
                    ref="searchInput"
                  />
                  <div class="input-group-prepend">
                    <button @click="search" type="button" class="btn icon_btn m-0">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div> -->
          <!--/Search column -->
          <div class="container-fluid main-board">
            <div class="row">
              <!-- Main-content -->
              <div v-if="fetched" class="title-row filters-row-list" :class="[viewType === 'list' ? 'row' : 'col-12']">
                <!-- Options div -->
                <div class="col-12 d-flex mb-1">
                  <!-- <span class="material-symbols-outlined mr-2"> table_chart </span><span class="h5">{{$t('workflow.workspace.title')}}</span> -->
                  <div class="board-info mb-0">
                    <pre v-show="false">{{currentBoard}}</pre>
                    <div class="title-board">
                      <!-- <code>{{currentBoard.id}}</code> -->
                      <input
                        v-if="currentBoard.edit"
                        v-focus
                        id="titleBoard"
                        @change="handleEdit(currentBoard, 'titleBoard', 'board', currentBoard.id)"
                        @keyup.enter="currentBoard.edit = !currentBoard.edit"
                        @keydown.esc="currentBoard.edit = false"
                        type="text"
                        v-model="currentBoard.title"
                        class="form-control border-0">
                      <h2 v-else class="mb-0" @click="$set(currentBoard, 'edit', !currentBoard.edit)">{{currentBoard.title}}</h2>
                      <!-- <code>{{column.deals.length}}</code> -->
                      <svg v-if="!currentBoard.edit" @click="$set(currentBoard, 'edit', !currentBoard.edit)" role="button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="heroicon text-yup" :class="viewType === 'grid' ? '' : ''"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" /></svg>
                      <!-- <span v-else class="material-symbols-outlined icon-success " @click="currentBoard.edit = !currentBoard.edit">done</span> -->
                    </div>
                    <div class="description-board">
                      <template v-if="currentBoard.due_date">
                        <p>{{currentBoard.created_at | displayDate(months, today, $i18n.locale)}} - {{currentBoard.due_date | displayDate(months, today, $i18n.locale)}}</p>
                      </template>
                      <template>
                        <p>{{$t('workflow.workspace.board.expire-none')}}</p>
                      </template>
                    </div>
                  </div>
                  <div class="board-actions">
                    <span id="copy-btn" class="material-symbols-outlined item-action" role="button" @click="copyLink">link</span>
                    <span v-if="false" class="material-symbols-outlined item-action add-user-btn" @click="showAddUser = !showAddUser" :class="showAddUser ? 'active' : ''" role="button">add</span>
                    <input type="text" :placeholder="$t('generic-str.search')" class="form-control add-user-input" :class="showAddUser ? 'active' : ''">
                    <button class="btn" v-b-toggle.sidebar-right-filter-task><span class="material-symbols-outlined">filter_list</span></button>
                    <button class="btn" v-b-toggle.sidebar-right-manage><span class="material-symbols-outlined settings-icon thin-icon">settings</span></button>
                  </div>
                  <!-- HTML title specified via default slot -->
                  <b-tooltip target="copy-btn" placement="bottom" triggers="click" :show.sync="showTooltip" custom-class="purple-tooltip">
                    Link copiado!
                  </b-tooltip>
                </div>
                <!--/Options div -->
                <div class="col-12 d-flex mb-5" v-if="false">
                  <!-- <div class="progress-wrapper d-flex align-items-center w-100">
                    <span class="label-file">Em progresso 60%</span>
                    <b-progress id="progressBar" :value="progressValue" :max="100" variant="yup"></b-progress>
                    <span id="percentage"></span>
                  </div> -->
                  <ul class="list-unstyled options-cat mb-0">
                    <!-- <li class="filter-opt"><svg xmlns="http://www.w3.org/2000/svg" fill="none" role="button" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="sort-descending"><path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" /></svg></li> -->
                    <li class="options-item" v-b-toggle.sidebar-right-filter-task><span class="material-symbols-outlined" role="button">filter_list</span></li>
                    <!-- <li class="options-item" :class="[viewType === 'grid' ? 'active' : '']"><span class="material-symbols-outlined" role="button" @click="changeType('grid')"> grid_view </span></li>
                    <li class="options-item" :class="[viewType === 'list' ? 'active' : '']"><span class="material-symbols-outlined" role="button" @click="changeType('list')"> list </span></li> -->
                  </ul>
                </div>
                <div class="col-12" v-if="viewType === 'list'">
                  <button @click="addColumnBoard" class="btn btn-add-col w-100 mb-4">{{$t('workflow.workspace.board.add-col')}} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button>
                </div>
              </div>
              <div class="row main-content-size sticky-content d-none" v-if="viewType === 'grid'">
                <div class="col-task-grid sticky-title" v-for="(column, index) in stages" :key="column.id">
                  <!-- Title -->
                  <div class="lead-col-name position-relative" :class="[viewType === 'grid' ? '' : 'col-12  col-sm-3', column.edit ? 'isEditing' : '']">
                    <!-- <code style="position:absolute; top: -33px">ID coluna: {{column.id}}</code> -->
                    <span class="title-list">
                      <input v-if="column.edit" v-focus :id="'titleInput' + index" @change="handleEdit(column, 'titleInput' + index, 'column', column.id)" @keyup.enter="column.edit = !column.edit" @keydown.esc="column.edit = false" type="text" :placeholder="column.title" class="form-control border-0 w-100">
                      <b v-else class="h5 col-name" @click="$set(column, 'edit', !column.edit)">{{column.title}}</b>
                      <!-- <code>{{column.deals.length}}</code> -->
                      <button
                        @click="$set(column, 'edit', !column.edit)"
                        v-if="!column.edit"
                        :class="viewType === 'grid' ? 'ml-auto' : ''">
                        <svg role="button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="heroicon text-yup">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                      </button>
                      <button
                        @click="column.edit = !column.edit"
                        v-else
                      >
                        <span class="material-symbols-outlined icon-success ml-auto">done</span>
                      </button>
                    </span>
                  </div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm-2"><span class="title-list">{{$t('workflow.workspace.board.owner')}}</span></div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm-2"><span class="title-list">{{$t('workflow.workspace.board.final-dt')}}</span></div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm" style="max-width: 20%;"><span class="title-list">{{$t('workflow.workspace.board.ch-com')}}</span></div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm-1"><span class="title-list">{{$tc('app.value', 1)}}</span></div>
                  <!-- <div class="d-flex align-items-center priority-col" :class="[viewType === 'grid' ? '' : 'col-12 col-sm']">
                    <span v-if="viewType === 'list'" class="title-list">{{$t('workflow.workspace.board.priority')}}</span>
                    <b-dropdown class="ml-auto" right variant="link p-0" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <span class="material-symbols-outlined config_dot">more_horiz</span>
                      </template>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> edit </span>{{$t('workflow.workspace.board.rename-status')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> mode_standby </span>{{$t('workflow.workspace.board.edit-status')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> archive </span>{{$t('workflow.workspace.board.archive-all')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> first_page </span>{{$t('workflow.workspace.board.collapse')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> done_all </span>{{$t('workflow.workspace.board.check-all')}}</b-dd-item>
                    </b-dropdown>
                  </div> -->
                  <hr class="purple">
                  <!--/Title -->
                </div>
              </div>
              <div :class="[viewType === 'list' ? 'col-12 col-sm-11 main-content' : 'row main-content-size position-relative']" v-if="fetched">
                <!-- Content dyn -->
                <template v-for="(column, index) in stages">
                  <div :id="`task-wrapper-${index}`" class="tasks-wrapper showing" :key="column.column_id">
                    <div :id="`collapse-col-${index}`" :class="[viewType === 'list' ? 'row mb-4 row-task-list' : 'col-task-grid collapse show width']">
                      <!-- Title -->
                      <div class="lead-col-name position-relative" :class="[viewType === 'grid' ? '' : 'col-12  col-sm-3', column.edit ? 'isEditing' : '']">
                        <!-- <code style="position:absolute; top: -33px">ID coluna: {{column.id}}</code> -->
                        <div class="full-title">
                          <div class="title-list">
                            <input
                              v-if="column.edit"
                              v-focus
                              :id="'titleInputTask' + index"
                              @change="handleEdit(column, 'titleInputTask' + index, 'column', column.id)"
                              @keyup.enter="column.edit = !column.edit"
                              @keydown.esc="column.edit = false"
                              type="text"
                              :placeholder="column.title"
                              class="form-control border-0 w-100">
                            <b v-else class="h5 col-name" @click="$set(column, 'edit', !column.edit)">{{column.title}}</b>
                            <!-- <code>{{column.deals.length}}</code> -->
                            <svg v-if="!column.edit" @click="$set(column, 'edit', !column.edit)" role="button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="heroicon text-yup" :class="viewType === 'grid' ? 'ml-auto' : ''"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" /></svg>
                            <span v-else class="material-symbols-outlined icon-success ml-auto" @click="column.edit = !column.edit">done</span>
                          </div>
                        </div>
                      </div>
                      <!-- <pre>{{column}}</pre> -->
                      <div v-if="viewType === 'list'" class="col-12 col-sm-2"><span class="title-list">{{$t('workflow.workspace.board.owner')}}</span></div>
                      <div v-if="viewType === 'list'" class="col-12 col-sm-2"><span class="title-list">{{$t('workflow.workspace.board.final-dt')}}</span></div>
                      <div v-if="viewType === 'list'" class="col-12 col-sm" style="max-width: 20%;"><span class="title-list">{{$t('workflow.workspace.board.ch-com')}}</span></div>
                      <div v-if="viewType === 'list'" class="col-12 col-sm-1"><span class="title-list">{{$tc('app.value', 1)}}</span></div>
                      <!-- <div class="d-flex align-items-center priority-col" :class="[viewType === 'grid' ? '' : 'col-12 col-sm']">
                        <span v-if="viewType === 'list'" class="title-list">{{$t('workflow.workspace.board.priority')}}</span>
                        <b-dropdown class="ml-auto col-task-opts" right variant="link p-0" toggle-class="text-decoration-none" no-caret>
                          <template #button-content>
                            <span class="material-symbols-outlined config_dot">more_horiz</span>
                          </template>
                          <b-dd-item href="#"><span class="material-symbols-outlined"> edit </span>{{$t('workflow.workspace.board.rename-status')}}</b-dd-item>
                          <b-dd-item href="#"><span class="material-symbols-outlined"> mode_standby </span>{{$t('workflow.workspace.board.edit-status')}}</b-dd-item>
                          <b-dd-item href="#" @click="archiveColumn(column)"><span class="material-symbols-outlined"> archive </span>{{$t('workflow.workspace.board.archive-all')}}</b-dd-item>
                          <b-dd-item href="#"><span class="material-symbols-outlined"> done_all </span>{{$t('workflow.workspace.board.check-all')}}</b-dd-item>
                        </b-dropdown>
                      </div> -->
                      <hr class="purple">
                      <div class="offering_values">
                        <b-button :id="`offering_values_${index}`">
                          <span class="material-symbols-outlined text-yup">monetization_on</span> {{column.offering_values.estimated | currency}}
                        </b-button>
                        <b-tooltip :target="`offering_values_${index}`" placement="bottom">
                          <div class="offering_values_currency">
                            <p>Únicos: {{column.offering_values.single | currency}}</p>
                            <p>Recorrentes: {{column.offering_values.recurrent | currency}}</p>
                          </div>
                        </b-tooltip>
                      </div>
                      <!--/Title -->
                      <!-- Inner content -->
                      <draggable :class="[viewType === 'list' ? 'task-list w-100' : 'task-grid']" :list="column.deals" :animation="200" ghost-class="ghost-card" group="tasks" @start="onDragStart" @end="onDragEnd" @change="onChangeTask(column.deals)" dragClass="dragginCursor">
                        <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                        <task-card
                          v-for="(task, index) in column.deals"
                          :key="task.id"
                          :task="task"
                          :index="index"
                          :length="column.deals.length"
                          :viewType="viewType"
                          @updateFetch="fetch()"
                          class="mt-3 cursor-grab"
                          :class="[viewType === 'grid' ? 'card' : '']" />
                        <!-- </transition-group> -->
                        <!-- <pre>{{column.deals}}</pre> -->
                      </draggable>
                      <!--/Inner content -->
                      <div class="add-card-wrapper">
                        <button class="btn btn-add-card" @click="showModalCard(column.id, index, column.title)" v-b-toggle.sidebar-right-task>
                          Criar negociação
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button>
                      </div>
                      <div class="d-none">{{addingColumn.position = column.index}}</div>
                    </div>
                    <template>
                      <!-- <button class="glass" role="button" data-toggle="collapse" :data-target="`#collapse-${index}`">
                        <p>{{column.title}}</p>
                      </button> -->
                      <button class="glass" role="button" @click="toggleColumn(index)">
                        <p>{{column.title}}</p>
                      </button>
                    </template>
                    <!-- <span v-if="viewType === 'grid'" class="material-symbols-outlined text-yup toggle-column" role="button" data-toggle="collapse" :data-target="`#collapse-${index}`">first_page</span> -->
                    <button @click="toggleColumn(index)" class="btn btn-toggle-col" style="height: fit-content"><span :id="`column-toggle-icon-${index}`" v-if="viewType === 'grid'" class="material-symbols-outlined text-yup toggle-column" role="button">first_page</span></button>
                  </div>
                </template>
                <!--/Content dyn -->
                <!-- Column add -->
                <div v-if="addingColumn.edit" :class="[viewType === 'list' ? 'row mb-4 row-task-list' : 'col-task-grid d-block']">
                  <!-- Title -->
                  <div class="lead-col-name add-column" :class="[viewType === 'grid' ? '' : 'col-12  col-sm-3', addingColumn.edit ? 'isEditing' : '']">
                    <span class="title-list">
                      <input v-if="addingColumn.edit" v-focus :id="'titleInputAdd'" v-model="columnName" @keyup.enter="addingColumn.edit = !addingColumn.edit" v-on:keyup.13="createColumn" @keydown.esc="addingColumn.edit = false" type="text" :placeholder="addingColumn.title" class="form-control border-0 w-100">
                      <b v-else class="h5 col-name" @click="$set(addingColumn, 'edit', !addingColumn.edit)">{{addingColumn.title}}</b>
                      <svg v-if="!addingColumn.edit" @click="$set(addingColumn, 'edit', !addingColumn.edit)" role="button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="heroicon text-yup" :class="viewType === 'grid' ? 'ml-auto' : ''"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" /></svg>
                      <span v-else class="material-symbols-outlined icon-success ml-auto" @click="addingColumn.edit = !addingColumn.edit">done</span>
                    </span>
                  </div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm-2"><span class="title-list">{{$t('workflow.workspace.board.owner')}}</span></div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm-2"><span class="title-list">{{$t('workflow.workspace.board.final-dt')}}</span></div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm" style="max-width: 20%;"><span class="title-list">{{$t('workflow.workspace.board.ch-com')}}</span></div>
                  <div v-if="viewType === 'list'" class="col-12 col-sm-1"><span class="title-list">{{$tc('app.value', 1)}}</span></div>
                  <!-- <div class="d-flex align-items-center priority-col" :class="[viewType === 'grid' ? '' : 'col-12 col-sm']">
                    <span v-if="viewType === 'list'" class="title-list">{{$t('workflow.workspace.board.priority')}}</span>
                    <b-dropdown class="ml-auto" right variant="link p-0" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <span class="material-symbols-outlined config_dot">more_horiz</span>
                      </template>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> edit </span>{{$t('workflow.workspace.board.rename-status')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> mode_standby </span>{{$t('workflow.workspace.board.edit-status')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> archive </span>{{$t('workflow.workspace.board.archive-all')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> first_page </span>{{$t('workflow.workspace.board.collapse')}}</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> done_all </span>{{$t('workflow.workspace.board.check-all')}}</b-dd-item>
                    </b-dropdown>
                  </div> -->
                  <hr class="purple">
                  <!--/Title -->
                </div>
                <!--/Column add -->
                <div class="btn-add-wrapper" :class="stages.length === 0 && !addingColumn.edit ? 'noColumn' : ''" v-if="viewType === 'grid'">
                  <button @click="addColumnBoard" class="btn btn-add-col w-100 mb-4">{{$t('workflow.workspace.board.add-step')}} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button>
                </div>
                <span class="loading-task-request" :class="{'qt-loader qt-loader-mini qt-loader-right qt-loader-purple':isSendingTask}" :disabled="isSendingTask"></span>
                <span id="completedTask" class="material-symbols-outlined completed">done</span>
              </div>
              <!--/Main-content -->
              <div class="loading w-100 min-h-300" v-else>
                <LoadingAnim />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <products-side-bar :users="[]" @update="fetchOfferings" />
  </div>
</template>

<script>
import 'primevue/resources/themes/saga-blue/theme.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'primevue/resources/primevue.min.css';
// import 'primeicons/primeicons.css';
import $ from 'jquery';
import bootstrap from 'bootstrap'; // Collapse horizontal
// @ is an alias to /src
import axios from '@/plugins/axios';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import draggable from 'vuedraggable';
import TaskCard from '@/components/rebranding/workflow/TaskCard.vue';
import moment from 'moment';
import SelectInput from '@/components/SelectInput.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import ProductsSideBar from '@/components/rebranding/workflow/detail/list/ProductsSideBar.vue';
import Calendar from 'primevue/calendar';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { clearTimeout } from '../../../../public/assets/vendor/lodash/_freeGlobal';
// import Pagination from '@/components/rebranding/Pagination.vue';
// import HiddenInput from '@/components/HiddenInput.vue';
// import WhatsService from '@/services/whatsapp.service';
// import Darkmode from '@/components/rebranding/SetTheme.vue';

const webApi = axios();

export default {
  name: 'Board',
  components: {
    PageHeader,
    draggable,
    TaskCard,
    LoadingAnim,
    SelectInput,
    Calendar,
    ProductsSideBar,
    // Pagination
    // HiddenInput,
    // Darkmode,
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
    displayDate(date, monthNames, localeToday, locale) {
      const today = new Date();
      const current = new Date(date);

      if (today.toDateString() === current.toDateString()) {
        return localeToday;
      }

      const months = monthNames;
      const y = current.getFullYear();
      const m = current.getMonth();
      const d = `${current.getDate()}`.padStart(2, 0);

      return (locale === 'en' ? `${months[m]} ${d}, ${y}` : `${d} ${months[m]}, ${y}`);
    },
  },
  data() {
    return {
      updating: false,
      breadcrumbLinks: {
        1: '/pipelines',
        2: `/pipelines/${this.$route.params.id}`,
      },
      breadcrumbInfo: {
        text: '',
        value: '',
      },
      currentBoard: {},
      fetched: false,
      seen: false,
      isSending: false,
      isSendingTask: false,
      searchQuery: null,
      addUserQuery: null,
      url: null,
      columnName: '',
      leadName: '',
      leadDescription: '',
      taskComment: '',
      taskStatus: '',
      progressValue: 60,
      uploadPercentage: 0,
      cardDate: '',
      cardChannels: [],
      cardChannelsOptions: [
        { text: 'E-mail', value: 'email' },
        { text: 'RCS', value: 'rcs' },
        { text: 'SMS', value: 'sms' },
        { text: 'WhatsApp', value: 'whatsapp' },
      ],
      cardValue: '',
      cardDescription: '',
      cardPriority: 'normal',
      cardPriorityOptions: [{ text: 'Baixo', value: 'low' }, { text: 'Normal', value: 'medium' }, { text: 'Alto', value: 'high' }, { text: 'Urgente', value: 'urgent' }],
      taskDate: null,
      taskValue: null,
      selectedStatus: '',
      selectedStage: null,
      stageIndex: null,
      columnTitle: null,
      totalDeals: 4,
      stages: [],
      customToggle: false,
      currentTask: {
        value: null,
        due_date: null,
      },
      showTooltip: false,
      showTooltip2: false,
      task:
        {
          title: this.$tc('app.title', 1),
          edit: false,
        },
      dragging: false,
      updateTaskPosition: {},
      updateTaskColumn: {},
      // pages: 1,
      // Test purpose
      // randomNumber: Number,
      viewType: 'grid',
      editingTitle: false,
      statusType: ['Ativo', 'Finalizado', 'Pausado', 'Arquivado'],
      boardDate: '',
      isAddingColumn: false,
      showAddUser: false,
      addResponsible: false,
      price: null,
      timeout: null,
      innerTime: null,
      addingUser: false,
      usersAndDepartments: [],
      userAndDepCard: [],
      userQuery: '',
      userQueryCard: '',
      userQueryCardEdit: {
        id: '',
        name: '',
      },
      manageTeamList: [],
      manageTeamListCard: [],
      offerings: [],
      offering: {
        id: null,
        recurrence: 'single',
        discount: 0,
        quantity: 1,
      },
      savingTeam: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      months: this.$t('primevue.monthNames'),
      today: this.$t('primevue.today'),
      addingColumn:
        {
          title: this.$tc('app.title', 1),
          edit: false,
          position: null,
        },
      // statusSort: [],
      /* columns: [
        {
          title: 'Contato feito',
          tasks: [
            {
              id: 1,
              name: 'Lead Adrian Schubert',
              // owner: [
              //   {
              //     user1: 'John',
              //     user2: 'Adrian Schubert',
              //     user3: 'Adrian Schubert',
              //   }
              // ],
              description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat enim animi reiciendis repudiandae eveniet itaque nesciunt veniam temporibus hic quia. Provident, laudantium recusandae temporibus magnam vel voluptate illum dolorum aperiam.',
              date: '25 de Fevereiro',
              ch_com: [{ text: 'WhatsApp', value: 'whatsapp' }, { text: 'RCS', value: 'rcs' }],
              value: '400,00',
              priority: 'high',
            },
            {
              id: 2,
              name: 'Lead Violet Gates',
              description: 'Quo vero molestiae at aut deserunt hic reiciendis tempore possimus, facere nesciunt quod ex a asperiores voluptatibus nostrum tenetur assumenda iure illo.',
              // owner: '',
              date: '10 de Janeiro',
              ch_com: [{ text: 'E-mail', value: 'email' }, { text: 'RCS', value: 'rcs' }],
              value: '3000,00',
              priority: 'normal',
            },
          ],
        },
        {
          title: 'Proposta enviada',
          tasks: [
            {
              id: 3,
              name: 'Lead Steve Jobs',
              // owner: '',
              description: 'Quia nulla, quae, omnis rem doloribus, voluptates laborum est eaque soluta consectetur accusantium cumque in? Illum amet quas, est aspernatur minima dolores! Soluta perspiciatis voluptatibus quas maiores optio laboriosam.',
              date: '3 de Março',
              ch_com: [{ text: 'WhatsApp', value: 'whatsapp' }, { text: 'SMS', value: 'sms' }, { text: 'RCS', value: 'rcs' }],
              value: '1000,00',
              priority: 'urgent',
            },
            {
              id: 4,
              name: 'Lead Yassine Smith',
              // owner: '',
              description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. A sit tempore quo quibusdam, omnis non beatae necessitatibus esse deleniti vel tenetur alias eum?',
              date: '4 de Abril',
              ch_com: [{ text: 'WhatsApp', value: 'whatsapp' }, { text: 'SMS', value: 'sms' }, { text: 'E-mail', value: 'email' }, { text: 'RCS', value: 'rcs' }],
              value: '2000,00',
              priority: 'urgent',
            },
          ],
        },
      ], */
    };
  },
  created() {
    this.fetch();
    console.log('Lang: ', this.$i18n.locale);
    // this.fetchUsersDepartments();
  },
  mounted() {
    this.$root.$on('getTaskDetails', (e) => {
      this.showTaskDetails(e);
    });
    const setDragCursor = (value) => {
      const html = document.getElementsByTagName('html').item(0);
      html.classList.toggle('grabbing', value);
    };

    this.fetchUsersDepBoard();
    this.fetchOfferings();
  },
  beforeDestroy() {
    // Destroying instance to avoid multiple future further calls
    this.$root.$off('getTaskDetails');
  },
  watch: {
    updateTaskPosition: {
      handler(val, oldVal) {
        console.log('Watch: updateTaskPosition watch: ', val, '\nOld value: ', oldVal);
      },
      deep: true,
      immediate: true,
    },
    updateTaskColumn: {
      handler(val, oldVal) {
        console.log('Watch: updateTaskColumn watch: ', val, '\nOld value: ', oldVal);
      },
      deep: true,
      immediate: true,
    },
    addTaskSideBar: {
      handler(val, oldVal) {
        console.log('Watch: Sidebar watch: ', val, '\nOld value: ', oldVal);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggleColumn(index) {
      let icon = $(`#column-toggle-icon-${index}`);
      $(`#collapse-col-${index}`).collapse('toggle');
      $(`#task-wrapper-${index}`).toggleClass('showing');
      if (icon.text() === 'first_page') {
        icon = icon.text('last_page');
      } else {
        icon = icon.text('first_page');
      }
    },
    addColumnBoard() {
      if (this.stages.length < 31) {
        this.addingColumn.edit = !this.addingColumn.edit;
      } else {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: this.$t('workflow.workspace.board.max-col'),
          type: 'danger',
        });
      }
    },
    insertToTeamCard() {
      const l = this.manageTeamListCard;
      const n = this.userQueryCard;

      if (!n) return;

      const exists = l.some((item) => item.id === n.id);

      if (exists) return;

      l.push(this.userQueryCard);
    },
    fetchUsersDepBoard() {
      webApi.get(`/pipelines/${this.$route.params.id}/team`).then(
        (response) => {
          // console.log('Fetch user n dep response: ', response, 'query: ', search);
          this.userAndDepCard = response.data;
          this.manageTeamList = this.userAndDepCard;
          console.log('Users array: ', this.userAndDepCard);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchOfferings() {
      webApi.get('/pipelines/offerings').then(
        (response) => {
          this.offerings = response.data;
          console.log('this.offerings', this.offerings);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    insertToTeam() {
      const l = this.manageTeamList;
      const n = this.userQuery;

      if (!n) return;

      const exists = l.some((item) => item.id === n.id);

      if (exists) return;

      l.push(this.userQuery);
    },
    saveTeam() {
      const boardId = this.$route.params.id;
      const team = { departments: [], users: [] };

      this.manageTeamList.forEach((item) => {
        if (item.is_department) {
          team.departments.push({ id: item.id, add: true });
        } else if (item.is_user) {
          team.users.push({ id: item.id, add: true });
        }
      });

      this.savingTeam = true;

      webApi.put(`/pipelines/${boardId}/team`, team).then(
        (response) => {
          this.savingTeam = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Novos integrantes adicionados',
            type: 'success',
          });
          this.toggleSidebar('sidebar-right-manage');
        },
        (error) => {
          this.savingTeam = false;
          this.$toast.show({
            title: 'Erro ao salvar',
            content: error.message,
            type: 'danger',
          });
        },
      );
    },
    fetchUsersDepartments(search, selected) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.usersAndDepartments = [];

        webApi.get(`/pipelines/teams/available?query=${encodeURIComponent(search)}`).then(
          (response) => {
            console.log('Fetch user n dep response: ', response, 'query: ', search);
            this.usersAndDepartments = response.data;
            console.log('Users array: ', this.usersAndDepartments);
          },
          (error) => {
            this.content = error;
          },
        );
      }, 1000);
    },
    addUserSearch() {
      console.log('Searching user...');
    },
    updateOfferingValue() {
      this.stages = this.stages.map((stage) => {
        const values = { recurrent: 0, single: 0, estimated: 0 };

        /*
        recurrence  "single"
        quantity  1
        discount  "0.00"
        */

        stage.deals.map((deal) => {
          const subValues = { recurrent: 0, single: 0 };

          deal.offerings?.forEach((offering) => {
            const recurrence = offering.pivot.recurrence;
            const quantity = parseInt(offering.pivot.quantity, 10);
            const discount = typeof offering.amount === 'string' ? parseFloat(offering.pivot.discount) : offering.pivot.discount;

            let value = typeof offering.amount === 'string' ? parseFloat(offering.amount) : offering.amount;

            if (quantity > 1) value *= quantity;

            if (discount > 0) value -= discount;

            if (recurrence === 'recurrent') {
              values.recurrent += value;
              subValues.recurrent += value;
            } else {
              values.single += value;
              subValues.single += value;
            }
          });

          deal.offering_values = subValues;

          return deal;
        });

        values.estimated = values.recurrent + values.single;

        stage.offering_values = values;

        return stage;
      });
    },
    search() {
      this.fetched = false;
      console.info('Search function');
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      // let searchedObj = null;
      webApi.get(`/pipelines/${this.$route.params.id}`).then(
        (response) => {
          console.log('Search response: ', response.data.stages);
          this.currentBoard = response.data;
          console.warn('currentBoard', this.currentBoard);
          // response.data.columns.forEach((column, i) => {
          //   console.log('Item: ', column.deals.filter((item) => word.toLowerCase().split(' ').every((v) => item.title.toLowerCase().includes(v))), 'index: ', i);
          //   result = column.deals.filter((item) => word.toLowerCase().split(' ').every((v) => item.title.toLowerCase().includes(v)));
          //   this.stages[i].deals = null;
          //   this.stages[i].deals = result;
          //   console.log('Search result: ', this.stages[i].deals);
          // });
          this.stages = response.data.stages;
          this.stages.forEach((column, i) => {
            console.log('Teste: ', column);
            console.log('Item: ', column.deals.filter((item) => word.toLowerCase().split(' ').every((v) => item.title.toLowerCase().includes(v))), 'index: ', i);
            result = column.deals.filter((item) => word.toLowerCase().split(' ').every((v) => item.title.toLowerCase().includes(v)));
            this.stages[i].deals = null;
            this.stages[i].deals = result;
            console.log('Search result: ', this.stages[i].deals);
          });
          console.log('Final search result: ', this.stages);
          this.stages = response.data.stages.map((stage) => {
            const values = { recurrent: 0, single: 0, estimated: 0 };

            /*
            recurrence  "single"
            quantity  1
            discount  "0.00"
            */

            stage.deals.map((deal) => {
              const subValues = { recurrent: 0, single: 0 };

              deal.offerings?.forEach((offering) => {
                const recurrence = offering.pivot.recurrence;
                const quantity = parseInt(offering.pivot.quantity, 10);
                const discount = typeof offering.amount === 'string' ? parseFloat(offering.pivot.discount) : offering.pivot.discount;

                let value = typeof offering.amount === 'string' ? parseFloat(offering.amount) : offering.amount;

                if (quantity > 1) value *= quantity;

                if (discount > 0) value -= discount;

                if (recurrence === 'recurrent') {
                  values.recurrent += value;
                  subValues.recurrent += value;
                } else {
                  values.single += value;
                  subValues.single += value;
                }
              });

              deal.offering_values = subValues;

              return deal;
            });

            values.estimated = values.recurrent + values.single;

            stage.offering_values = values;

            return stage;
          });

          /* response.data.stages.forEach((column, i) => {
            const deals = column.deals;

            result = deals.filter((item) => word.toLowerCase().split(' ').every((v) => item.title.toLowerCase().includes(v)));

            console.log('Item: ', result, 'index: ', i);

            this.stages[i].deals = result;

            Vue.set(this.stages, i, { cards: result });

            console.log('Search result: ', this.stages[i].deals);
          }); */

          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    /* handleValue() {
      this.currentTask.value.replace(/[R$ ]/g, '');
    }, */
    archiveColumn(e) {
      const toArchive = [];
      Object.keys(e.deals).forEach((item) => {
        console.log('Archive column: ', e.deals[item].id);
        // Vue.set(toArchive, e.deals[item].id);
        toArchive.push(e.deals[item].id);
      });
      // console.log('To send: ', toArchive);
      webApi.post('/workflows/cards/archive', { cards: toArchive }).then(
        (response) => {
          console.log('To archive: ', response);
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('workflow.workspace.card.edit.success'),
            type: 'success',
          });
        },
        (error) => {
          this.fetched = true;
          console.error({ error });
          this.content = error;
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
        },
      );
    },
    copyLink(e) {
      const dummy = document.createElement('input');
      const text = window.location.href;

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      const time = setInterval(() => {
        clearInterval(time);
        if (e === 'sidebar') {
          this.showTooltip2 = !this.showTooltip2;
        } else {
          this.showTooltip = !this.showTooltip;
        }
      }, 1000);
    },
    handleCalendar() {
      // const date = this.currentTask.due_date.toISOString();
      // setTimeout(() => {
      //   // console.log('Calendar: ', this.$refs.calendarDate._computedWatchers.inputFieldValue.value);
      //   // this.taskDate = this.$refs.calendarDate._computedWatchers.inputFieldValue.value;
      //   console.log('Date: ', date);
      // }, 500);
    },
    onDragStart(e) {
      this.dragging = true;
      const root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
      setTimeout(() => {
        // e.target.classList.add('grabbing');
        root.setAttribute('class', 'draggable-cursor');
      }, 50);
    },
    onDragEnd(e) {
      this.dragging = false;
      const root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
      root.classList.remove('draggable-cursor');
    },
    checkCustomFieldStatus(e) {
      /* eslint-disable */
      if (e) {
        return Object.values(this.currentTask).some((el) => el.status === e);
      } else {
        return Object.values(this.currentTask).some((el) => el.status === this.selectedCustomFieldId);
      }
      /* eslint-enable */
    },
    onChangeTask(e) {
      clearTimeout(this.timeout);
      this.isSendingTask = true;
      /* const index = e.findIndex((object) => object.id === 'fd8a9623-f4cb-43cf-96be-1f8436b8c1f0');
      console.log('Task: ', index); // 👉️ 1 */
      // console.log('Task: changes:', e);
      // console.log('Task: index', { ...this.stages.deals, ...{ position: 1 } });
      // console.log('Task: ', Object.assign(this.stages[0].deals[1], { position: 3 }));
      Object.keys(e).forEach((item, index) => {
        // console.log('Task: object: ', e[item], 'index: ', index, 'key to change (position): ', e[item].position);
        if (typeof e[item].position === 'number' && e[item].position !== index) {
          e[item].position = index;
        }
        // console.log('Task: object: ', e[item], 'index: ', index, 'NEW key value (position): ', e[item].position);
        // console.log('Task: task id: ', e[item].id, 'position: ', e[item].position);
      });
      Object.keys(this.stages).forEach((item) => {
        // console.log('Task: card collection: ', this.stages[item].deals);
        Object.keys(this.stages[item].deals).forEach((task) => {
          this.stages[item].deals[task].column_id = this.stages[item].id;
          console.log('Task: task id: ', this.stages[item].deals[task].id, 'position: ', this.stages[item].deals[task].position);
          // this.updateTaskPosition[this.stages[item].deals[task].id] = this.stages[item].deals[task].position;
          Vue.set(this.updateTaskPosition, this.stages[item].deals[task].id, this.stages[item].deals[task].position);
          console.log('Task: COLUMN: ', this.stages[item].id);
          // this.updateTaskColumn[this.stages[item].deals[task].id] = this.stages[item].id;
          Vue.set(this.updateTaskColumn, this.stages[item].deals[task].id, this.stages[item].id);
          console.log('Task: Item: ', this.stages[item].deals[task]);
        });
      });
      this.timeout = setTimeout(() => {
        console.log('Request...');
        this.updateTasks();
      }, 2000);
    },
    updateTasks() {
      webApi.put('/pipelines/deals/reposition', { items: this.updateTaskPosition, column: this.updateTaskColumn }).then(
        (response) => {
          clearTimeout(this.innerTime);
          console.log('Update tasks: ', response);
          // this.$toast.show({
          //   title: this.$t('generic-str.success'),
          //   content: this.$t('workflow.workspace.card.edit.success'),
          //   type: 'success',
          // });
          this.isSendingTask = false;
          const completedTask = document.getElementById('completedTask');
          completedTask.classList.add('show');
          this.innerTime = setTimeout(() => {
            completedTask.classList.remove('show');
          }, 2000);
        },
        (error) => {
          this.fetched = true;
          console.error({ error });
          this.content = error;
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSendingTask = false;
        },
      ).finally(() => {
        this.updateOfferingValue();
      });
    },
    showTaskDetails(e) {
      this.leadName = '';
      console.log('Show details: ', e);
      this.currentTask = e;
      if (typeof this.currentTask.user === 'object') {
        this.seen = true;
      } else {
        this.seen = false;
      }
      this.userQueryCardEdit = e.users?.[0];
      this.toggleSidebar('sidebar-right-show-task');
    },
    handleEdit(e, id, element, elementId) {
      // this.fetched = false;
      const column = e;
      const inputValue = document.getElementById(id).value;
      console.log('Editing! ', column);
      column.title = inputValue;
      console.log('New title', column.title);
      if (element === 'column') {
        this.updating = true;
        webApi.put(`/pipelines/stages/${elementId}`, { title: inputValue }).then(
          (response) => {
            console.log('Edit response: ', response);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('workflow.workspace.card.edit.success'),
              type: 'success',
            });
            // this.fetch();
          },
          (error) => {
            this.fetched = true;
            console.error({ error });
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        ).finally(() => {
          this.updating = false;
        });
      } else if (element === 'board') {
        this.updating = true;
        webApi.put(`/pipelines/${elementId}`, { title: inputValue }).then(
          (response) => {
            console.log('Edit response: ', response);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('workflow.workspace.card.edit.success'),
              type: 'success',
            });
            // this.fetch();
          },
          (error) => {
            this.fetched = true;
            console.error({ error });
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        ).finally(() => {
          this.updating = false;
        });
      }
    },
    removeResponsible() {
      if (this.userQueryCardEdit !== undefined) {
        this.userQueryCardEdit.id = 'none';
        this.userQueryCardEdit.name = '';
      } else {
        this.userQueryCardEdit = {};
        this.userQueryCardEdit.id = 'none';
        this.userQueryCardEdit.name = '';
      }
      this.seen = false;
    },
    isComment() {
      return new Promise((resolve, reject) => {
        webApi.post('/workflows/comments',
          {
            card: this.currentTask.id,
            body: this.taskComment,
          }).then(
          (response) => {
            console.log('Comment response: ', response);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('workflow.workspace.card.edit.success'),
              type: 'success',
            });
            this.isSending = false;
            // this.taskComment = '';
            // this.toggleSidebar('sidebar-right-show-task');
            return resolve(response);
            // this.fetch();
          },
          (error) => {
            this.isSending = false;
            this.fetched = true;
            console.error({ error });
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            return reject(error);
          },
        );
      });
    },
    isSaving() {
      return new Promise((resolve, reject) => {
        this.isSending = true;
        console.log({ 'this.userQueryCardEdit': this.userQueryCardEdit });
        // const date = moment(this.currentTask.due_date).format();
        // const date2 = new Date('2023-11-15 18:53:40');
        // console.log('Date: ', this.currentTask.due_date, 'ISO date: ', date);
        // console.log('New price: ', price, 'Old price: ', this.currentTask.value);
        const date = new Date(this.currentTask.due_date).toISOString();
        let price = '0000';
        if (this.currentTask.amount) {
          price = this.currentTask.amount.replace(/[R$ ]/g, '').replace(/[,]/g, '').replace(/[.]/g, '');
        }
        if (!this.currentTask.title) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Insira um título!',
            type: 'danger',
          });
        } else if (!this.currentTask.description) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Insira uma descrição!',
            type: 'danger',
          });
        } else if (!this.currentTask.priority) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Campo prioridade é obrigatório!',
            type: 'danger',
          });
        } else if (!date) {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Campo data é obrigatório!',
            type: 'danger',
          });
        } else {
          let payload = {};
          if (this.offering.id !== null) {
            const offering = { ...this.offering };
            offering.attach = true;
            payload = {
              title: this.currentTask.title,
              description: this.currentTask.description,
              priority: this.currentTask.priority,
              due_date: date,
              assign_to: this.userQueryCardEdit?.id,
              offerings: [offering],
            };
          } else {
            payload = {
              title: this.currentTask.title,
              description: this.currentTask.description,
              priority: this.currentTask.priority,
              due_date: date,
              assign_to: this.userQueryCardEdit?.id,
            };
          }
          webApi.put(`/pipelines/deals/${this.currentTask.id}`, payload).then(
            (response) => {
              console.log('Edit task response: ', response);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('workflow.workspace.card.edit.success'),
                type: 'success',
              });
              this.isSending = false;
              this.leadName = '';
              this.leadDescription = '';
              this.toggleSidebar('sidebar-right-show-task');
              return resolve(response);
              // this.fetch();
            },
            (error) => {
              this.isSending = false;
              this.fetched = true;
              console.error({ error });
              this.content = error;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
              return reject(error);
            },
          ).finally(() => {
            this.isSending = false;
            this.fetch();
            /* this.offering = {
              id: null,
              recurrence: 'single',
              discount: 0,
              quantity: 1,
            }; */
          });
        }
      });
    },
    handleEditTask() {
      // this.isSending = true;
      let myPromise = Promise;
      if (!this.currentTask.priority) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo prioridade está com valor incorreto',
          type: 'danger',
        });
        this.isSending = false;
      } else if (this.taskComment) {
        myPromise = Promise.all([this.isComment(), this.isSaving()]);
        this.taskComment = '';
        this.currentTask = {};
        setTimeout(() => {
          this.stages = [];
          // this.fetch();
        }, 500);
      } else {
        myPromise = Promise.all([this.isSaving()]);
        this.currentTask = {};
        setTimeout(() => {
          this.stages = [];
          // this.fetch();
        }, 500);
      }
    },
    handleEditAdd(e, id) {
      const column = e;
      const inputValue = document.getElementById(id).value;
      column.title = inputValue;
    },
    fetch() {
      let dateString = '';
      /* this.form.page = page;
      this.fetched = false;
      MailService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          console.log('MailService.getBlacklists', this.blacklists, response.last_page);
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      ); */
      this.fetched = false;
      webApi.get(`/pipelines/${this.$route.params.id}`).then(
        (response) => {
          console.log('Board response:\n ', response, '\nLength: ', response.data.stages.length);
          this.fetched = true;
          this.currentBoard = response.data;
          this.breadcrumbInfo.value = this.currentBoard.title;
          this.stages = response.data.stages.map((stage) => {
            const values = { recurrent: 0, single: 0, estimated: 0 };

            /*
            recurrence  "single"
            quantity  1
            discount  "0.00"
            */

            stage.deals.map((deal) => {
              const subValues = { recurrent: 0, single: 0 };

              deal.offerings?.forEach((offering) => {
                const recurrence = offering.pivot.recurrence;
                const quantity = parseInt(offering.pivot.quantity, 10);
                const discount = typeof offering.amount === 'string' ? parseFloat(offering.pivot.discount) : offering.pivot.discount;

                let value = typeof offering.amount === 'string' ? parseFloat(offering.amount) : offering.amount;

                if (quantity > 1) value *= quantity;

                if (discount > 0) value -= discount;

                if (recurrence === 'recurrent') {
                  values.recurrent += value;
                  subValues.recurrent += value;
                } else {
                  values.single += value;
                  subValues.single += value;
                }
              });

              deal.offering_values = subValues;

              return deal;
            });

            values.estimated = values.recurrent + values.single;

            stage.offering_values = values;

            return stage;
          });

          console.log('[response.data.stages]', response.data.stages);

          dateString = this.$i18n.locale === 'en' ? 'MM/DD/YYYY h:mm:ss' : 'DD/MM/YYYY h:mm:ss';
          Object.keys(this.stages).forEach((item) => {
            // console.log('Task: card collection: ', this.stages[item].deals);
            Object.keys(this.stages[item].deals).forEach((task) => {
              console.log('Populate: task id: ', this.stages[item].deals[task].id, 'position: ', this.stages[item].deals[task].position);
              this.updateTaskPosition[this.stages[item].deals[task].id] = this.stages[item].deals[task].position;
              console.log('Populate: COLUMN: ', this.stages[item].id);
              this.updateTaskColumn[this.stages[item].deals[task].id] = this.stages[item].id;
              if (this.stages[item].deals[task].due_date) {
                this.stages[item].deals[task].labelDate = moment(this.stages[item].deals[task].due_date).locale(this.$i18n.locale).utc().format(dateString);
                // Vue.set('labelDate', moment(this.stages[item].deals[task].due_date).locale(this.$i18n.locale).utc().format(dateString));
                // this.stages[item].deals[task].due_date = `${moment(this.stages[item].deals[task].due_date).locale(this.$i18n.locale).utc().format(dateString)}`;
              }
              if (this.stages[item].deals[task].contact_id && this.stages[item].deals[task].contact.last_name) {
                this.stages[item].deals[task].contact.name = `${this.stages[item].deals[task].contact.first_name} ${this.stages[item].deals[task].contact.last_name}`;
                /* Object.keys(this.stages[item].deals[task].contact).forEach((contactInfo) => {
                  console.log('TESTE: COLUMN: ', this.stages[item].deals[task].contact[contactInfo]);
                }); */
              }
            });
          });
        },
        (error) => {
          this.content = error;
        },
      );
      // this.fetchBoardInfo(this.$route.params.id);
    },
    fetchBoardInfo(e) {
      const word = e;
      let result = null;
      let dateString = '';
      this.fetched = false;
      webApi.get('/pipelines/deals').then(
        (response) => {
          console.log('Board response:\n ', response);
          this.fetched = true;
          this.currentBoard = response.data.data;
          console.log('TESTE: currentBoard: ', this.currentBoard);
          result = this.currentBoard.find((item) => word.toLowerCase().split(' ').every((v) => item.id.toLowerCase().includes(v)));
          console.log('TESTE: Filtered board info: ', result);
          this.currentBoard = result;
          dateString = this.$i18n.locale === 'en' ? 'MMMM DD, YYYY' : 'DD MMMM, YYYY';
          this.boardDate = `${moment(result.created_at).locale(this.$i18n.locale).utc().format(dateString)} - ${this.$t('app.current')}`;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    /* sortArray() {
      this.statusSort = this.statusType.sort((a, b) => 0.5 - Math.random());
    }, */
    changeType(type) {
      this.viewType = type;
    },
    showModal() {
      this.$refs.addTask.show();
    },
    hideModal() {
      this.columnName = '';
      this.$refs.addTask.hide();
    },
    createColumn(e) {
      this.isSending = true;
      this.updating = true;
      webApi.post('/pipelines/stages', {
        title: this.columnName,
        position: this.stages.length,
        pipeline: this.$route.params.id,
      }).then((response) => {
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: this.$t('workflow.workspace.board.create.success'),
          type: 'success',
        });
        this.fetch();
        // this.hideModal();
        this.columnName = '';
        this.addingColumn.edit = false;
        this.isSending = false;
      }).catch((error) => {
        console.error({ error });
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
          content: error.message,
          type: 'danger',
        });
        this.isSending = false;
      }).finally(() => {
        this.updating = false;
      });
    },
    addCard(leadName) {
      if (!this.leadName) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira um título!',
          type: 'danger',
        });
      } else if (!this.leadDescription) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Insira uma descrição!',
          type: 'danger',
        });
      } else if (!this.taskStatus) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo prioridade é obrigatório!',
          type: 'danger',
        });
      } else if (!this.taskDate) {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Campo data é obrigatório!',
          type: 'danger',
        });
      } else {
        // const offering = { ...this.offering };
        let price = '0000';
        // offering.attach = true;

        this.isSending = true;
        if (this.taskValue) {
          price = this.taskValue.replace(/[R$ ]/g, '').replace(/[,]/g, '').replace(/[.]/g, '');
        }
        // price = price.replace(/[,]/g, '');
        console.log('Price: ', price);
        webApi.post('/pipelines/deals', {
          title: this.leadName,
          position: this.stages[this.stageIndex].deals.length,
          description: this.leadDescription,
          priority: this.taskStatus,
          stage: this.selectedStage,
          due_date: this.taskDate,
          // amount: price,
          assign_to: this.userQueryCard?.id,
          // offerings: [offering],
        }).then((response) => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('workflow.workspace.card.create.success'),
            type: 'success',
          });
          this.leadName = '';
          this.task.title = this.$tc('app.title', 1);
          this.leadDescription = '';
          this.taskStatus = '';
          this.taskDate = '';
          this.taskValue = '';
          this.fetch();
          // this.hideModalCard();
          this.toggleSidebar('sidebar-right-task');
          this.isSending = false;
        }).catch((error) => {
          console.error({ error });
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.leadName = '';
          this.leadDescription = '';
          this.isSending = false;
        }).finally(() => {
          this.isSending = false;
          /* this.offering = {
            id: null,
            recurrence: 'single',
            discount: 0,
            quantity: 1,
          }; */
        });
      }
    },
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
    /* Teste */
    addColumn(e) {
      const colName = document.getElementById('column-name');
      // if (Object.values(this.stages).indexOf(e) > -1) {
      //   this.errorMessage(colName, 'Teste');
      // }
      // Check if columns has the same name/title
      if (e === '' || !e) {
        this.errorMessage(colName, this.$t('workflow.workspace.board.no-value'));
      }
      if (this.stages.find((x) => x.title === e)) {
        this.errorMessage(colName, this.$t('workflow.workspace.board.col-exists'));
      } else if (e) {
        this.stages.push({ title: e, tasks: [] });
        this.columnName = '';
        this.hideModal();
      } // else if (!document.getElementById('warning-text')) {
      // this.errorMessage(colName, this.$t('workflow.workspace.board.no-value'));
      // } else {
      //   this.warningAnim(document.getElementById('warning-text'));
      // }
    },
    addCardTest(leadName, cardDate, cardChannels, cardValue, cardPriority) {
      this.totalDeals += 1; // Fake auto-increment
      this.stages[this.selectedStage].tasks.push(
        {
          id: this.totalDeals,
          name: leadName,
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat enim animi reiciendis repudiandae eveniet itaque nesciunt veniam temporibus hic quia. Provident, laudantium recusandae temporibus magnam vel voluptate illum dolorum aperiam.',
          date: cardDate,
          ch_com: cardChannels,
          value: cardValue,
          priority: cardPriority,
        },
      );
      this.leadName = '';
      this.cardDate = '';
      this.cardChannels = [];
      this.cardValue = '';
      this.cardPriority = 'normal';
      this.hideModalCard();
    },
    errorMessage(relativeTo, message) {
      // Create an "div" node:
      const node = document.createElement('div');
      const errorElm = document.getElementById('warning-text');
      node.setAttribute('id', 'warning-text');

      // Create a text node:
      const textnode = document.createTextNode(message);

      // Append the text node to the "div" node:
      node.appendChild(textnode);

      if (!errorElm) {
        // First time create warning div and append
        this.warningAnim(node); // Animate
        relativeTo.appendChild(node);
      } else {
        // If exist, just change text, then animate
        errorElm.innerHTML = message;
        this.warningAnim(errorElm); // Animate
      }
    },
    /* handleAddColumn() {

    }, */
    showModalCard(e, index, title) {
      this.selectedStage = e;
      this.stageIndex = index;
      this.columnTitle = title;
      // this.$refs.addCard.show();
    },
    hideModalCard() {
      this.leadName = '';
      this.leadDescription = '';
      this.cardDate = '';
      this.cardChannels = [];
      this.cardValue = '';
      this.cardPriority = 'normal';
      this.$refs.addCard.hide();
    },
    warningAnim(elm) {
      if (elm) {
        elm.classList.add('error-anim');
        setTimeout(() => {
          elm.classList.remove('error-anim');
        }, 180);
      } else {
        console.info('Animation failed, missing obj as reference');
      }
    },
  },
  directives: {
    // Input focus
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>
<style scoped lang="scss">
.col-task-opts {
  height: 32px !important;

  &::v-deep() button {
    height: 32px !important;
  }
}

.full-title {
  width: 100%;
}

.offering_values_currency {
  p {
    margin-bottom: 0;
  }
}

.offering_values {
  float: right;
  position: relative;
  // right: -12% !important;
  padding-top: 3px;

  &::v-deep() {
    button, button:hover, button:focus, button:active {
      display: flex;
      align-items: center;
      gap: .125rem;
      background: transparent !important;
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      color: inherit !important;
      box-shadow: none !important;
      height: auto !important;
      font-size: .82rem;
    }
  }
}

.page-header {
  padding-bottom: 2rem;
}
.page-content {
  padding-bottom: 2rem !important;
}

.test-sticky {
  position: sticky;
  top: 100px;
}

/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
.container-add, .container-catalogs {
  max-width: 1740px;
}

/* List */
.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  // padding-top: 8px;
  // padding-bottom: 8px;
  white-space: nowrap;
  @media (min-width: 1660px) {
    padding: 0 10px;
  }
}
.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;
  li {
    color: var(--gray-font-color-5);
  }
  .options-item.active {
    span {
      color: var(--clr-yup-purple);
    }
  }
}
.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover, &:focus {
    color: var(--clr-yup-purple-hover) !important;
    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}
hr.purple {
  width: 98%;
  border-top: 5px solid #6B23DC3D;
  border-radius: 75px;
  clear: both;
}
::v-deep() {
  .content-row {
    display: flex;
    background-color: var(--background-2);
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    >div {
      display: flex;
      align-items: center;
    }
    @media (min-width: 768px) {
      div:last-child {
        justify-content: center;
      }
    }
  }
  .task-list {
    .content-row {
      align-items: center;
      /* padding-left: 0; */
      padding-right: 0;
      >div {
        // min-height: 72px;
      }
    }
  }
  .task-grid {
    width: 256px;
    min-width: 256px;

    .content-row {
      & > div {
        // min-height: 50px;
      }
    }
    .card:last-of-type {
      margin-bottom: 0;
    }
  }
}
.options-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.75rem;
  margin-bottom: unset;
  li, .dropdown-toggle span {
    color: var(--gray-font-color-5);
  }
  .dropdown-toggle:hover, .dropdown-toggle:focus {
    color: var(--gray-font-color);
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: '\e5cd';
  font-family: 'Material Symbols Outlined';
  color: #FFF;
  transform: scale(1.6);
}
.tag-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: unset;
  li {
    color: var(--gray-font-color-5);
  }
}
::v-deep() {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }
  .pagination {
    justify-content: end;
  }
}
.prod-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-position: center;
}
.info-wrapper {
  display: flex;
  flex-direction: column;

  a:not(.material-symbols-outlined), span:not(.material-symbols-outlined) {
    font-weight: 400;
    padding: 8px 20px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: unset !important;
  }
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: unset !important;
    color: var(--gray-font-color-5);
  }
}
/* Workflow */
::v-deep() {
  .sort-descending {
    width: auto;
    height: 29px;
  }

  .filter-opt {
    display: flex;
  }

  /* Card workspace */
  .card-workspace {
    /* width: 40%; */
    margin-bottom: 0;
    /* margin-right: 50px; */
    /* flex: 0 1 45%; */
    height: 100%;

    .card-header {
      display: flex;

      .dropdown {
        margin-left: auto;
      }

      .dropdown-toggle span {
        color: var(--gray-font-color-5);
      }

      .dropdown-toggle:hover,
      .dropdown-toggle:focus {
        color: var(--gray-font-color);
      }

      span {
        height: fit-content;
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;

      p {
        font-weight: bold;
        margin-bottom: 1.5rem;
      }

      .progress-yup {
        margin-top: 1.5rem;
      }
    }
    .card-footer {
      display: flex;
      gap: 5px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    small {
      color: var(--gray-font-color);
    }
  }
  .workspace-row {
    .col-workspace {
      margin-bottom: 50px;
    }
    .col-workspace:nth-of-type(2) .card.card-workspace {
      box-shadow: 0px 3px 26px 5px rgba(107, 35, 220, 0.16) !important;
    }
    @media (min-width: 768px) {
      padding: 0 8rem 0 1rem;
      .col-workspace:nth-of-type(2n) {
        // padding-left: 5rem !important;
        .card-workspace {
          margin-right: 3rem;
        }
      }
      .col-workspace:nth-of-type(2n + 1) {
        // padding-right: 5rem !important;
        .card-workspace {
          margin-left: 3rem;
        }
      }
    }
    @media screen and (min-width: 1360px) and (max-width: 1660px) {
      .col-workspace:nth-of-type(2n) {
        .card-workspace {
          margin-right: 1rem;
        }
      }
      .col-workspace:nth-of-type(2n + 1) {
        .card-workspace {
          margin-left: 1rem;
        }
      }
      padding: 0 2rem 0 1rem;
    }
  }
  .img-add {
    width: 100%;
    margin: auto;
    max-width: 250px;
    max-height: 250px;
  }
  /* @media (min-width: 768px) {
    .card-workspace {
      min-width: 480px;
    }
  } */

  .progress-yup {
    width: 100%;
    /* &:before {
      content: '';
      background-image: url(/assets/import_csv.svg);
      width: 31px;
      height: 36px;
    } */
  }
  /*/Card workspace */
  .col-calendar {
    background-color: #f7f7f7;
    padding-top: 2rem;

    .hour-wrapper {
      .card:nth-of-type(2n + 1):not(.card:first-of-type) {
        margin-left: 50px !important;
      }
    }

    > p {
      margin-bottom: 3rem;
    }
    .date-wrapper {
      display: flex;
      .date {
        font-weight: bold;
        color: var(--foreground);
      }
      .control-calendar {
        display: flex;
        gap: 6px;
        margin-left: auto;
      }
      span {
        color: var(--gray-font-color-4);
        font-weight: 400;
      }
    }
    .hour-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 3.5rem 0 3.5rem;
      &.blank p {
        width: 100%;
      }
      /* &.scheduled p {
        height: 100%;
      } */
      hr {
        flex: 1 0 auto;
        // border-top-style: dashed;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E3E6FF' stroke-width='4' stroke-dasharray='15%2c 30' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
        border: unset !important;
        height: 6%;
      }
      &.blank .badge,
      .badge.blank {
        opacity: 0;
        cursor: default;
      }

      &.scheduled .card {
        flex: 1 0 auto;
        margin-left: 15px;
        border-left: 5px solid #c2c2c2;
        .card-body,
        .card-footer {
          padding: 3px 5px;
        }
        .card-body {
          display: flex;
          color: var(--gray-font-color-4);
          font-weight: 400;

          .time {
            display: flex;
            align-items: center;
            gap: 3px;
            margin-left: auto;
            * {
              font-size: 0.875rem;
            }
          }
        }
        .card-footer {
          font-size: 0.85rem;
          padding-bottom: 5px;
        }

        // Status
        &.normal {
          border-left: 5px solid #5a99ec;

          .badge.badge-normal, .badge.badge-medium {
            border-radius: 8px;
          }
        }
        &.high {
          border-left: 5px solid #ffb200;

          .badge.badge-high {
            border-radius: 8px;
          }
        }
        &.urgent {
          border-left: 5px solid #ff0000;

          .badge.badge-high {
            border-radius: 8px;
          }
        }
      }
    }
    .hour-wrapper.active {
      hr {
        border-top: 2px solid var(--clr-yup-purple) !important;
        background-image: unset !important;
      }
    }
  }

  /* Board */
  .item-action {
    color: var(--clr-yup-purple);
    background-color: #F4EEFD;
    border-radius: 50%;
    padding: .5rem;
    font-size: 28px !important;
  }
  .board-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: .5rem;
    .btn {
      width: fit-content;
      height: fit-content;
      padding: 0;
    }
    .settings-icon {
      color: var(--gray-font-color-4);
    }
    .add-user-btn {
      transition: .5s;
      margin-right: 0;
      &.active {
        margin-right: 260px;
      }
    }
    .add-user-input {
      width: 0;
      opacity: 0;
      padding: 0;
      transition: .5s;
      position: absolute;
      right: 42px;
      max-width: 260px;
      transition-property: width, padding, opacity;
      pointer-events: unset;
      z-index: -1;
      &.active {
        width: 100%;
        opacity: 1;
        padding: .325rem;
        pointer-events: all;
        z-index: 1;
      }
    }
  }
  .team-column, .user-column, .date-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .user-column, .date-wrapper {
    align-items: center;
  }
  .date-wrapper {
    gap: .5rem;
    margin-right: 1rem;
    span {
      color: var(--gray-font-color-4);
    }
  }
  .progress-wrapper {
    position: relative;
    // border: 1px solid #E0E3E6;
    border-radius: 8px;
    // margin: 0 5% 0 0;
    margin-top: 1rem;
    padding: 1rem;
    max-width: 88%;

    .progress {
      width: 100%;
    }

    #percentage {
      margin-top: 1rem;
      margin-left: .725rem;
      font-weight: 400;
    }

    .label-file {
      position: absolute;
      right: 40px;
      top: -10px;
      font-weight: 400;
    }

    img {
      margin-right: 1rem;
    }
  }
  .right-options {
    display: flex;
    margin-left: auto;
    span {
      color: var(--gray-font-color-4);
    }
  }
  .name-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: .5rem;
    p {
      margin-bottom: .125rem;
    }
  }
  .copy-wrapper {
    display: flex;
    align-items: center;
    gap: .25rem;
    margin-left: auto;
    font-weight: 400;
  }
  .tasks-wrapper {
    display: flex;
    height: 100%;
    &.showing {
      overflow-y: hidden;
      max-height: 66vh;
      overflow-x: hidden;
      flex: 0 0 310px;
      @media (min-height: 950px) {
        max-height: 75vh !important;
      }
    }
    .collapse {
      visibility: hidden;
    }
    .collapse.show {
        visibility: visible;
        display: flex !important;
    }
    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        -webkit-transition-property: height, visibility;
        transition-property: height, visibility;
        -webkit-transition-duration: 0.35s;
        transition-duration: 0.35s;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
    }
    .collapsing.width {
        -webkit-transition-property: width, visibility;
        transition-property: width, visibility;
        transition: width .35s ease;
        width: 0;
        height: auto;
    }
    /* .collapsing.width ~ .glass {
      animation-name: fade2;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      animation-duration: .5s;
      animation-direction: normal;
    } */
    .glass {
      display: flex;
      align-items: baseline;
      position: relative;
      transition: .5s;
      background-color: #6b23dc14;
      border: 1px solid;
      border-color: #e5e5e5;
      border-radius: 1rem;
      // height: 100vh;
      height: 20.6875rem;
      padding: 0 15px;
      opacity: 1;
      margin-left: 0px;
      width: 57px;
      max-width: 57px;
      p {
        color: var(--clr-yup-purple);
        position: relative;
        top: 170px;
        margin-bottom: 0;
        transform: rotate(-90deg);
        text-transform: uppercase;
        white-space: nowrap;
        width: 27px;
      }
    }
    .collapse.width ~ .glass {
      margin-right: 10px;
    }
    .collapsing.width ~ .glass {
      margin-left: -40px;
      opacity: 0;
    }
    .collapse.width.show ~ .glass {
      display: none;
      margin-left: -40px;
    }
    .collapse.width:not(.collapse.width.show) ~ div {
      .glass {
        display: block !important;
        position: relative;
        background: inherit;
        overflow: hidden;
        width: 70%;
        height: 70%;
        border-radius: 16px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.15);
        z-index: 1;
        opacity: 1;
      }
      .glass:before {
        content: '';
        position: absolute;
        background: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -20px;
        box-shadow: inset 0 0 500px rgba(255, 255, 255, .4);
        filter: blur(10px);
        z-index: -1;
      }
    }
    .toggle-column {
      height: fit-content;
      padding: .75rem 0 1rem;
      position: sticky;
      top: 0;
    }
  }
  .btn-add-card:not(.col-task-grid .btn-add-card) {
    color: var(--clr-yup-purple);
    // text-decoration: underline;
    font-size: 1rem;
    font-weight: 400;
  }
  .btn-add-col, .col-task-grid .btn-add-card {
    // background-color: #6b23dc14;
    background-color: #f3edfd;
    border-color: #e5e5e5;
    color: var(--clr-yup-purple);
    font-weight: 400;
    border-radius: 8px;

    &:hover,
    &:focus {
      // background-color: #6b23dc5e;
      background-color: #c8adf2;
      color: #772ced;
    }
  }
  .col-task-grid .btn-add-card {
    width: 100%;
    height: auto;
    font-size: 1rem;
    border-radius: 1rem;
    padding: 1rem;
  }

  .main-content-size {
    width: 100%;
    // max-height: 70vh;
    // overflow: auto;
    &.sticky-content {
      overflow: unset;
      position: relative;
      z-index: 5;
    }
    .noColumn {
      position: absolute !important;
      left: 40px;
      top: 0px;
    }
    .btn-add-wrapper{
      position: relative;
      order: 2;
    }
    .btn-add-col {
      // display: flex;
      width: 20.6875rem !important;
      transform: rotate(-90deg);
      padding: 1rem;
      // flex-direction: column;
      // align-items: center;
      // gap: 1.5rem;
      // flex-shrink: 0;
      position: absolute;
      // top: 20.6875rem;
      top: 18.9rem;
      left: 35px;
      transform-origin: left;
      margin: unset !important;
      height: auto;
      font-size: 1rem;
      border-radius: 1rem;
    }
  }

  .col-name {
    color: var(--foreground);
    font-weight: bold;
    margin-bottom: 0;
  }
  .col-name ~ svg {
    margin-left: 2rem;
  }

  .lead-name {
    span {
      font-weight: 400;
    }

    .lead-infos {
      span {
        color: var(--gray-font-color-5) !important;
      }
      p {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      display: flex;
      gap: 10px;
      margin-left: auto;
      @media (min-width: 1660px) {
        margin-right: 50px;
      }
    }
  }
  .owner-col {
    display: flex;
    gap: 10px;
    img {
      border-radius: 50%;
      width: 46px;
      height: 46px;
    }
  }
  .dt-expire {
    color: var(--gray-font-color-5);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .content-row.board .priority-col,
  .priority-col {
    justify-content: start;
    padding-left: 15px;
    max-width: 12%;
    /* @media (min-width: 1660px) {
      max-width: 12%;
    } */
    /* .badge {
      margin: 8px 20px;
    } */
  }
  .row-task-list {
    width: 100%;
    > .col-12.col-sm, > .col-12.col-sm-2, .col-12.col-sm-1 {
      display: flex;
      align-items: center;
    }
    .priority-col {
      padding-right: 0;
    }
    .col-name, .title-list {
      font-size: 0.875rem;
    }
    .lead-col-name {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      .title-list {
        display: flex;
        align-items: center;
        width: 98%;
        .col-name ~ svg {
          margin-left: auto;
        }
      }
    }
  }
  .col-task-grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    // width: 280px;
    // min-width: 280px;
    padding-left: .75rem;
    padding-right: .75rem;
    max-width: 280px;
    // &:not(.sticky-title) {
    //   display: flex;
    // }
    &.collapse {
      width: 0;
      opacity: 0;
    }
    &.collapse.show {
      width: auto;
      max-width: 280px;
      height: fit-content;
      opacity: 1;
    }
    &.sticky-title {
      height: fit-content;
      align-items: center;
    }
    .add-column {
      width: 85%;
    }
    .lead-col-name {
      display: flex;
      align-items: center;
      float: left;
      margin-left: 2% !important;
      // height: 10%;
      flex: 1 0 auto;
      width: 83%;
      // width: 100%;
      // height: 50px;
      height: 40px;
      flex-wrap: wrap;
      background-color: var(--background-3);
      .title-list {
        display: flex;
        align-items: center;
        width: 100%;

        svg, span.material-symbols-outlined {
          position: relative;
          // margin-left: 10px !important;
          // margin-right: 10px;
          cursor: pointer;
        }

        input {
          padding-left: 0;
          // padding-right: 0;
          height: auto;
        }
      }
    }
    .priority-col {
      float: right;
      // height: 10%;
      height: 39px;
      padding: 0;
      padding-right: 10px;
      position: relative;
      z-index: 6;
      background-color: var(--background-2);
    }
    &:not(.col-task-grid.collapsing) .priority-col, &:not(.col-task-grid.collapsing) .lead-col-name {
      position: sticky !important;
      // top: 70px;
      top: 0;
      z-index: 6;
    }
    &:not(.col-task-grid.collapsing) .priority-col ~ hr {
      position: sticky !important;
      // top: 120px;
      top: 50px;
      z-index: 5;
    }
    .col-name {
      overflow: hidden;
      // max-width: 164px;
      font-size: .875rem;
      width: 100%;
    }
    .add-card-wrapper {
      width: 100%;
      position: sticky;
      bottom: 0;
      z-index: 6;
    }
    hr.purple {
      margin-top: 0;
      margin-bottom: 0;
    }
    .lead-col-name.isEditing {
      // flex-grow: 0 !important;
      // flex-shrink: 1 !important;
      // width: 217.6px;
      // max-width: 85%;
      flex: 1 0 auto;
      width: 100%;
    }
  }
  .board.sortable-chosen {
    background-color: #762ced11;
    // background-color: #762ced25;
    box-shadow: 0 0 25px -3px #762ced52;
  }
  .sortable-chosen {
    cursor: grab;
    &.ghost-card {
      cursor: grabbing !important;
    }
  }
  @media (min-width: 768px) {
    /* .title-row {
      padding: 0 1.5rem;
    } */
  }
  @media (max-width: 1660px) {
    .main-content {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}
.btn-close-modal {
  position: absolute;
  top: 15px;
  right: 0;
  span {
    color: var(--gray-font-color);
  }
}
::v-deep() {
  .btn-toggle-col {
    position: sticky;
    top: 0;
    padding-left: .25rem;
    padding-right: .25rem;
  }
  #warning-text {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-top: 1rem;
    background-color: var(--urgent-bg);
    color: var(--urgent-fg);
    padding: 5px 10px;
    border-radius: 8px;
    transition: .5s;
    font-weight: 400;
    height: 100%;

    &::before {
      content: '\e000';
      font-family: 'Material Symbols Outlined';
      font-size: 1.25rem;
    }
  }

  #warning-text.error-anim {
    animation: horizontal-shaking 0.18s infinite;
  }

  @keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  }
}
.filters-wrapper, .filters-row-list {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.main-content-size {
  /* -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; */
  margin-right: 0;
  margin-left: 0;
  flex-wrap: nowrap;
  // overflow: auto;
  // max-height: 60vh;
  overflow-x: auto;
  height: 100%;
}

/* Board info */
.board-info {
  margin-bottom: 3.5rem;
  .title-board {
    display: flex;
    align-items: center;
    h2 {
      display: flex;
      align-items: center;
      height: 38px;
    }
    svg, span.material-symbols-outlined {
      margin-left: 1rem;
    }
    input {
      min-width: 70px;
      padding: 0;
    }
  }
  .description-board {
    p {
      text-transform: capitalize;
      color: var(--gray-font-color);
      margin-bottom: 0;
    }
  }
}

::v-deep() {
  .completed {
    position: relative;
    order: 3;
    left: 70px;
    top: 10px;
    background-color: var(--sent-bg);
    color: var(--sent-fg);
    height: fit-content;
    border-radius: 50%;
    padding: .256rem;
    opacity: 0;
    pointer-events: none;
    transform: scale(.75);
    &.show {
      animation-name: fade;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      animation-duration: 2s;
      animation-direction: normal;
    }
  }
  @keyframes fade{
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade2{
    0% {
      opacity: 0;
    }
    60% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
  .toggle-custom {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 400;
      .material-symbols-outlined {
      margin-left: auto;
      margin-right: 5px;
      transition: .5s;
      &.rotate {
        transform: rotate(-180deg);
      }
    }
  }
  .task-grid {
    // height: 100%;
    // height: calc(100% - 90px);
    height: 650px;
    margin-bottom: 1rem;
    padding-bottom: 100px;
    max-height: 100vh !important;
    overflow-y: auto;
    scrollbar-width: thin;
    &.widthTask {
      width: 256px !important;
      min-width: 256px;
    }
  }
  .input-group {
    .control-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      font-weight: 400;
      color: var(--gray-font-color-4);
      margin-bottom: .5rem;
    }
  }
  .loading-task-request {
    position: relative;
    // top: -10px;
    // left: 45%;
    z-index: 1;
    height: fit-content;
    order: 3;
    left: 60px;
    top: 30px;
  }
  .qt-loader.qt-loader-mini.qt-loader-purple::before {
    border: 2px solid var(--clr-yup-purple);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  /* PrimeVue */
  .pi.pi-calendar {
    font-family: 'Material Symbols Outlined';
  }
  .b-sidebar-body {
    .p-calendar {
      width: 100%;
      .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        margin-right: 0;
      }
    }
    .p-calendar-w-btn .p-inputtext {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .p-calendar-w-btn .p-datepicker-trigger {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .p-inputtext {
      border-color: var(--form-border) !important;
      border-right: none;
    }
    .p-button {
      background: transparent;
      border: 1px solid var(--form-border);
      border-left: none;
      color: var(--gray-font-color-4);
      &:hover, &:focus {
        background: unset;
        border: 1px solid unset;
      }
    }
  }
  .pi-calendar::before {
    font-size: 24px;
    content: "\e935";
  }
}

/* Sidebar add prod */
::v-deep() .b-sidebar {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    margin-bottom: 2rem;
    .sidebar-title {
      color: var(--foreground);
      font-size: 1.25rem;
    }
    .sidebar-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    p {
      display: block;
      font-size: 1rem;
      margin-bottom: 1px;
    }
    p, span.material-symbols-outlined {
      color: var(--gray-font-color-2);
    }
  }
  .title-column {
    margin-bottom: 2rem;
    .title-task {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      * {
        font-size: 1.625rem;
      }
      .not-editing {
        margin-right: 1rem;
      }
    }
  }
  .responsable-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
    margin-bottom: 2.5rem;
    p {
      font-size: 1rem;
      margin-right: .5rem;
      margin-bottom: 0;
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      &:last-of-type {
        margin-right: .5rem;
      }
    }
  }
  .profile-wrapper {
    display: flex;
    flex-wrap: wrap;
    .profile-around {
      position: relative;
      transition: .5s;
      &:hover {
        transform: scale(1.1);
        z-index: 1;
      }
      &:hover .btn-remove-responsible {
        color: var(--urgent-fg);
      }
    }
    .profile {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex-shrink: 0;
      overflow: hidden;
      box-shadow: 0 0 0 4px #fff;
      pointer-events: none;
      color: #fff;
      svg {
        width: 20px;
        height: 20px;
      }

      &.faded-icon {
        background-color: var(--c-grey-100);
        color: var(--c-grey-400);
      }

      &.filled-icon {
        background-color: var(--c-blue-500);
        color: #fff;
      }
    }
  }
  .assign-user-column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .label-sidebar-item {
      width: fit-content !important;
      margin-right: .5rem;
    }
    .btn-add-responsible {
      display: flex;
      height: fit-content;
      padding: 0;
      margin-top: 0 !important;
      border-radius: 50%;
      box-shadow: 0px 0px 0px 2px var(--gray-font-color-4);
      transform: scale(.8);
      transition: .5s;
      > span {
        color: var(--gray-font-color-4);
        // font-size: 1rem;
      }
      &:hover, :focus {
        transform: scale(.9);
      }
    }
    .btn-remove-responsible {
      display: flex;
      position: absolute;
      color: var(--gray-font-color-5);
      background-color: #FFF;
      border-radius: 50%;
      top: -4px;
      right: -8px;
      padding: 0;
      height: fit-content;
      // z-index: 1;
      span {
        font-size: 1rem;
      }
    }
    svg {
      color: var(--gray-font-color);
      cursor: pointer;
      margin-top: 0 !important;
    }
  }
  .description-column {
    > p {
      font-size: 1rem;
      margin-bottom: .5rem;
    }
    & p:last-of-type {
      font-size: .875rem;
      color: var(--gray-font-color);
    }
    /* textarea {
      margin-bottom: 1px;
    } */
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;

    .sidebar-item {
      margin-bottom: 3rem;
      .label-sidebar-item {
        display: block;
        width: 100%;
        font-weight: 400;
        color: var(--foreground);
        // margin-bottom: 1.5rem;
      }
      img.profile-img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        margin-right: .5rem;
      }
      .label-sidebar-item ~ * {
        margin-top: 1rem;
      }
    }
  }
  header {
    >strong {
      order: -1;
    }
  }
  .title-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend:not(:first-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .create-custom-field {
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    /* .title-group {
      font-weight: 400;
      color: var(--gray-font-color-6);
      margin-bottom: 1rem;
    } */
    /* .custom-field-list {
      width: 100%;
      li {
        margin-bottom: .5rem;
        color: var(--gray-font-color-2);
        font-weight: 400;
      }
    } */
  }
}

.main-board {
  // min-height: calc(100vh - 193px);
  min-height: calc(100vh - 305px);

  & > .row {
    // min-height: calc(100vh - 193px);
    min-height: calc(100vh - 305px);
    // background-color: #fc0;

    .main-content-size {
      min-height: 66vh;
    }
  }
}

.profile {
  background-color: var(--clr-yup-purple);
  color: #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  margin-bottom: 1rem;
  .bar {
    display: flex;
    align-items: center;
    width: 42px;
  }

  .header-comment {
    gap: .82rem;

    time {
      color: var(--gray-font-color);
      font-size: .72em;
    }
  }

  header {
    padding-bottom: .245rem !important;
    font-weight: 400;
  }
}
.team-wrapper {
  display: flex;
  gap: 5px;
  .team-profile {
    background-color: var(--clr-yup-purple);
    color: #fff;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.darkmode {
  .btn-add-col, .col-task-grid .btn-add-card {
    background-color: #6B23DC3D;
    border-color: #6B23DC3D;
  }
}
</style>
